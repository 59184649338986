import { css } from 'astroturf';
import React from 'react';
import { Close } from 'react-bytesize-icons';
import Link from './link';

const styles = css`
  .policy {
    z-index: 10;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    transform: translateZ(200px);

    @media (max-width: 480px) {
      padding-right: 100px;
    }

    & > div {
      pointer-events: all;
      font-size: 0.8rem;
      background: rgb(255 255 255);
      color: #333338;
      padding: 0 0 0 1rem;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 0px, rgba(0, 0, 0, 0.05) 0px 1px 2px, rgba(0, 0, 0, 0.05) 0px 5px 15px;
      border-radius: 0.25rem;
      opacity: 1;
      transition: 0.25s ease-in-out opacity;
      display: flex;
      justify-content: flex-start;
      align-items: stretch;

      @supports (backdrop-filter: blur(10px)) {
        background: rgb(255 255 255/0.8);
        backdrop-filter: blur(10px) brightness(150%);
      }

      &.dismissed {
        opacity: 0;
      }

      & p {
        align-self: center;
        padding: 4px 0;
      }

      & button {
        flex: 0 0 48px;
        width: 48px;
        min-height: 48px;
        display: grid;
        place-items: center;
        border: 0;
        background: none;
        padding: 0;
        color: #000;
        margin-left: 0.5rem;

        border-radius: 0 0.25rem 0.25rem 0;
        transition: 0.25s ease-in-out background;
        background: #fff0;

        &:hover,
        &:focus {
          background: rgb(16 125 181 / 25%);
        }

        & svg {
          margin: auto;
        }
      }
    }
  }
`;

class Policy extends React.Component {
  constructor() {
    super();
    this.state = {
      dismissed: typeof window !== 'undefined' ? !!window.localStorage.getItem('dismissed') : true,
    };
  }

  componentDidMount() {
    this.setState({ dismissed: !!window.localStorage.getItem('dismissed') });
  }

  dismissPopup = () => {
    window.localStorage.setItem('dismissed', true);
    this.setState({ dismissed: true });
  };

  render() {
    const { dismissed } = this.state;
    return (
      <div className={styles.policy}>
        {!dismissed && (
          <div>
            <p>
              By using our site you agree to our <Link to="/privacy/">privacy policy</Link> and{' '}
              <Link to="/disclaimer/">disclaimer</Link>
            </p>
            <button type="button" onClick={this.dismissPopup} aria-label="Dismiss" title="Dismiss">
              <Close width={12} height={12} />
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default Policy;
