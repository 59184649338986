import { css } from 'astroturf';
import { StaticQuery, graphql } from 'gatsby';
import React, { Component } from 'react';

import Logo from '../../assets/images/icon.svg';
import Link from './link';

const activeStyle = {
  color: 'rgb(185, 218, 248)',
};
const styles = css`
  .footer {
    position: relative;
    overflow: hidden;
    background:
      repeating-conic-gradient(#0001 0 0.0001%, #0000 0 0.0002%),
      rgb(10, 21, 48) url(../../assets/images/10.jpg) top center/cover;
    background: rgb(10, 21, 48) url(../../assets/images/10.jpg) top center/cover;
    box-shadow:
      rgba(0, 0, 0, 0.15) 0px 1px 0px inset,
      rgba(0, 0, 0, 0.15) 0px 0px 8px inset,
      rgba(0, 0, 0, 0.175) 0px 20px 30px inset;
    padding: 6rem;
    padding-left: env(safe-area-inset-left, 2rem);
    padding-right: env(safe-area-inset-right, 2rem);
    @media (forced-colors: active) {
      border-top: 1px solid;
    }

    & > div {
      padding-left: 2rem;
      padding-right: 2rem;
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 2rem;
    }

    @media (min-width: 770px) {
      padding: 6rem 2rem 2rem;

      & > div {
        padding: 0 3rem;
        flex-direction: row;
        flex-wrap: wrap;
      }
    }

    & h4 {
      margin-bottom: 1rem;

      & svg {
        vertical-align: middle;
        margin-right: 0.25rem;
      }
    }

    & a {
      margin: 0;
      padding: 0;
      transition: 0.25s ease-in-out color;
      color: rgba(255, 255, 255, 0.75);

      &:hocus {
        background: none;
        color: rgba(255, 255, 255, 1);
        text-decoration: underline;
      }
    }
  }

  .block {
    position: relative;
    flex: 1 1 auto;
    padding: 0;
    color: #fff;

    &:first-child {
      flex: 1 1 200px;

      & ul {
        margin-top: -0.5rem;
      }
    }

    & span {
      font-size: 1rem;
      font-weight: bold;
      color: #fff;
      margin-bottom: 1rem;
    }

    & ul li {
      padding: 0.25rem 0;
      list-style: none;

      &.tagline {
        line-height: 1.5;
        padding-bottom: 0.7rem;
      }
    }

    & ul li a {
      color: rgba(255, 255, 255, 0.8);
      box-shadow: initial;
      background: none;
    }

    & a svg {
      width: 1rem;
      height: 1rem;
      vertical-align: middle;
      margin-top: -2px;
      margin-right: 0.25rem;
      fill: currentColor;
    }

    & > form {
      margin-bottom: 2rem;
    }

    & div a {
      box-shadow: initial;
      & div {
        opacity: 0.5;
        vertical-align: middle;
        transform: translateY(-2px);
        margin-right: 0.25rem;
        transition: 0.25s ease opacity;
      }
      &:hocus div {
        opacity: 1;
      }
    }
  }

  .legal {
    color: #fff;
    grid-column: 1 / -1;
    display: flex;
    flex-wrap: wrap;
    gap: 0 1rem;
  }
`;

class Footer extends Component {
  render() {
    return (
      <StaticQuery
        query={detailsQuery}
        render={(data) => (
          <footer id="footer" className={styles.footer}>
            <div>
              <div className={styles.block}>
                <h4>
                  <Logo /> Polypane
                </h4>

                <ul className={styles.cols}>
                  <li className={styles.tagline}>The browser for ambitious web developers.</li>
                  <li>
                    <Link to="https://dashboard.polypane.app/">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-user"
                        viewBox="0 0 24 24"
                        strokeWidth="1.25"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0"></path>
                        <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
                      </svg>
                      Manage your account
                    </Link>
                    <br />
                    <br />
                  </li>

                  <li>
                    <Link to="https://twitter.com/intent/user?screen_name=polypane">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true">
                        <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
                      </svg>{' '}
                      @Polypane on X/Twitter
                    </Link>
                  </li>
                  <li>
                    <Link to="https://instagram.com/polypane">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" aria-hidden="true">
                        <path d="M160,128a32,32,0,1,1-32-32A32.03667,32.03667,0,0,1,160,128Zm68-44v88a56.06353,56.06353,0,0,1-56,56H84a56.06353,56.06353,0,0,1-56-56V84A56.06353,56.06353,0,0,1,84,28h88A56.06353,56.06353,0,0,1,228,84Zm-52,44a48,48,0,1,0-48,48A48.05436,48.05436,0,0,0,176,128Zm16-52a12,12,0,1,0-12,12A12,12,0,0,0,192,76Z" />
                      </svg>{' '}
                      @Polypane on Instagram
                    </Link>
                  </li>
                  <li>
                    <Link to="https://join.slack.com/t/polypane/shared_invite/zt-1h9psk5dz-jAVl3HD91BUnWCqJhdDAlw">
                      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path d="M5.042 15.165a2.528 2.528 0 0 1-2.52 2.523A2.528 2.528 0 0 1 0 15.165a2.527 2.527 0 0 1 2.522-2.52h2.52v2.52zM6.313 15.165a2.527 2.527 0 0 1 2.521-2.52 2.527 2.527 0 0 1 2.521 2.52v6.313A2.528 2.528 0 0 1 8.834 24a2.528 2.528 0 0 1-2.521-2.522v-6.313zM8.834 5.042a2.528 2.528 0 0 1-2.521-2.52A2.528 2.528 0 0 1 8.834 0a2.528 2.528 0 0 1 2.521 2.522v2.52H8.834zM8.834 6.313a2.528 2.528 0 0 1 2.521 2.521 2.528 2.528 0 0 1-2.521 2.521H2.522A2.528 2.528 0 0 1 0 8.834a2.528 2.528 0 0 1 2.522-2.521h6.312zM18.956 8.834a2.528 2.528 0 0 1 2.522-2.521A2.528 2.528 0 0 1 24 8.834a2.528 2.528 0 0 1-2.522 2.521h-2.522V8.834zM17.688 8.834a2.528 2.528 0 0 1-2.523 2.521 2.527 2.527 0 0 1-2.52-2.521V2.522A2.527 2.527 0 0 1 15.165 0a2.528 2.528 0 0 1 2.523 2.522v6.312zM15.165 18.956a2.528 2.528 0 0 1 2.523 2.522A2.528 2.528 0 0 1 15.165 24a2.527 2.527 0 0 1-2.52-2.522v-2.522h2.52zM15.165 17.688a2.527 2.527 0 0 1-2.52-2.523 2.526 2.526 0 0 1 2.52-2.52h6.313A2.527 2.527 0 0 1 24 15.165a2.528 2.528 0 0 1-2.522 2.523h-6.313z" />
                      </svg>{' '}
                      Join our Slack community
                    </Link>
                  </li>
                </ul>
              </div>
              <nav className={styles.block} aria-labelledby="footer-menu1">
                <h4 id="footer-menu1">Product</h4>
                <ul className={styles.cols}>
                  <li>
                    <Link activeStyle={activeStyle} to="/docs/">
                      Docs
                    </Link>
                  </li>
                  <li>
                    <Link activeStyle={activeStyle} to="/integrations/">
                      Integrations
                    </Link>
                  </li>
                  <li>
                    <Link activeStyle={activeStyle} to="/docs/changelog/">
                      Changelog
                    </Link>
                  </li>
                  <li>
                    <Link activeStyle={activeStyle} to="/download/">
                      Download
                    </Link>
                  </li>
                  <li>
                    <Link activeStyle={activeStyle} to="/support/">
                      Support
                    </Link>
                  </li>
                  <li>
                    <Link activeStyle={activeStyle} to="/pricing/">
                      Pricing
                    </Link>
                  </li>
                </ul>
              </nav>
              <nav className={styles.block} aria-labelledby="footer-menu2">
                <h4 id="footer-menu2">Use cases</h4>
                <ul className={styles.cols}>
                  <li>
                    <Link activeStyle={activeStyle} to="/product-tour/?ref=responsive-design">
                      Responsive design
                    </Link>
                  </li>
                  <li>
                    <Link activeStyle={activeStyle} to="/accessibility/">
                      Accessibility
                    </Link>
                  </li>
                  <li>
                    <Link activeStyle={activeStyle} to="/site-quality/">
                      Site Quality
                    </Link>
                  </li>
                  <li>
                    <Link activeStyle={activeStyle} to="/social-media-previews/">
                      Social media previews
                    </Link>
                  </li>
                  <li>
                    <Link activeStyle={activeStyle} to="/quality-assurance/">
                      Quality Assurance
                    </Link>
                  </li>
                </ul>
              </nav>
              <nav className={styles.block} aria-labelledby="footer-menu3">
                <h4 id="footer-menu3">Tools &amp; Resources</h4>
                <ul className={styles.cols}>
                  <li>
                    <Link activeStyle={activeStyle} to="/color-contrast/">
                      Color contrast checker
                    </Link>
                  </li>
                  <li>
                    <Link activeStyle={activeStyle} to="/css-specificity-calculator/">
                      CSS specificity calculator
                    </Link>
                  </li>
                  <li>
                    <Link activeStyle={activeStyle} to="/create-workspace/">
                      Create Polypane workspace
                    </Link>
                  </li>
                  <li>
                    <Link activeStyle={activeStyle} to="/responsive-design-glossary/">
                      Responsive design glossary
                    </Link>
                  </li>
                  <li>
                    <Link activeStyle={activeStyle} to="/experimental-web-platform-features/">
                      Experimental Web Platform features
                    </Link>
                  </li>
                  <li>
                    <Link activeStyle={activeStyle} to="/resources/">
                      All free tools
                    </Link>
                  </li>
                  <li>
                    <Link activeStyle={activeStyle} to="/alternative/">
                      Alternatives
                    </Link>
                  </li>
                </ul>
              </nav>
              <nav className={styles.block} aria-labelledby="footer-menu4">
                <h4 id="footer-menu4">Company</h4>
                <ul className={styles.cols}>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link activeStyle={activeStyle} to="/about/">
                      About
                    </Link>
                  </li>
                  <li>
                    <Link activeStyle={activeStyle} to="/blog/">
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link activeStyle={activeStyle} to="/newsletter/">
                      Newsletter
                    </Link>
                  </li>
                  <li>
                    <Link activeStyle={activeStyle} to="/testimonials/">
                      Testimonials
                    </Link>
                  </li>
                  <li>
                    <Link activeStyle={activeStyle} to="/case-studies/">
                      Case studies
                    </Link>
                  </li>
                  <li>
                    <Link activeStyle={activeStyle} to="/accolades/">
                      Accolades
                    </Link>
                  </li>
                </ul>
              </nav>

              <div className={styles.legal}>
                <span>
                  &copy; {new Date().getFullYear()} {data.site.siteMetadata.company}
                </span>
                <Link to="/privacy/">Privacy</Link> <Link to="/legal/">Legal</Link>{' '}
                <Link to="/disclaimer/">Disclaimer</Link>{' '}
                <Link to="/accessibility-statement/">Accessibility Statement</Link>
              </div>
            </div>
          </footer>
        )}
      />
    );
  }
}

export default Footer;

const detailsQuery = graphql`
  query FooterQuery {
    site {
      siteMetadata {
        title
        company
        menuLinks {
          name
          link
        }
        footerLinks {
          name
          link
        }
      }
    }
  }
`;
